import React from "react"
import { Link } from "gatsby"
import DankeHeader from "../components/header/danke"
import Layout from "../components/layout"

const Danke = () => (
  <Layout>    
    <DankeHeader />
    <div className="text-center w-100 m-3">
      <Link to="/">Zur Startseite</Link>
    </div>
  </Layout>
)

export default Danke